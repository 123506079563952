import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import Option from "./Option";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../device";

import SplitText from "./SplitText";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const OuterContainer = styled.div`
  grid-area: 1/1/4/4;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 300px 2fr;
  background-color: black;
  @media only screen and ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: black;
  /* padding: 2rem; */
  border-radius: 10px;
  opacity: ${(props) => (props.videoHasStarted ? 1 : 0.85)};
  &:hover {
    opacity: 1;
  }
  transition: 300ms;
  min-height: 25%;
  width: 100%;
`;

const OptionContainer = styled.div`
  background-color: ${(props) => (props.isRestart ? "red" : "hotpink")};
  color: white;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  border: ${(props) =>
    props.isRestart ? "1px solid red" : "1px solid hotpink"};
  &:hover {
    transform: translate(0px, -3px);
  }
  transition: 60ms;
`;

const PromptContainer = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-area: 2/1/2/1;
  align-content: center;
  align-items: center;
  @media only screen and ${device.tablet} {
    grid-area: 1/3/4/4;
    justify-content: center;
  }
  color: white;
  justify-content: flex-start;
  border-radius: 10px;
  text-align: center;
  z-index: 99;
`;

const VideoOrImageContainer = styled.div`
  grid-area: 1/1/2/2;
  justify-content: center;
  @media only screen and ${device.tablet} {
    grid-area: 1/1/4/3;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const VideoPlayer = styled(ReactPlayer)`
  grid-area: 1/1/2/2;
`;

export default function MainView({ data }) {
  let { id } = useParams();
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const [passwordEntry, setPasswordEntry] = useState("");

  const location = useLocation();

  const {
    Location,
    Prompt,
    Video,
    Option1Text,
    Option1Destination,
    Option1Image,
    Option2Text,
    Option2Destination,
    Option2Image,
    Option3Text,
    Option3Destination,
    Option3Image,
    Option4Text,
    Option4Destination,
    Option4Image,
    Password,
    PasswordPrompt,
    PasswordDestination,
    PasswordSuccessText,
    ShowRestart,
    DownloadFile,
    DownloadImg,
    DownloadText,
  } = data[id];

  const maxDelayTime = 0.5 + (Prompt.length / 20) * 100;
  const handleFocus = (event) => event.target.select();

  return (
    <OuterContainer>
      <PromptContainer videoHasStarted={videoHasStarted}>
        <OptionsContainer>
          <SplitText prompt={Prompt} animationLength={maxDelayTime} />
          {Password && (
            <>
              <input
                onChange={(e) => {
                  e.preventDefault();
                  setPasswordEntry(e.target.value);
                }}
                defaultValue="Enter..."
                className="tac"
                style={{
                  marginBottom: "auto",
                  marginTop: "auto",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onFocus={handleFocus}
              />
              {passwordEntry === Password ? (
                <TransitionGroup component={null}>
                  <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={100}
                  >
                    <Link to={"/" + PasswordDestination}>
                      <OptionContainer isRestart={false}>
                        <h2>{PasswordSuccessText}</h2>
                      </OptionContainer>
                    </Link>
                  </CSSTransition>
                </TransitionGroup>
              ) : (
                <OptionContainer style={{ opacity: 0 }} isRestart={false}>
                  <h2>.</h2>
                </OptionContainer>
              )}
            </>
          )}
          {Option1Text && Option1Destination && (
            <Option
              text={Option1Text}
              destination={Option1Destination}
              image={Option1Image}
              key={Option1Text + location.key}
            />
          )}
          {Option2Text && Option2Destination && (
            <Option
              text={Option2Text}
              destination={Option2Destination}
              image={Option2Image}
              key={Option2Text + location.key}
            />
          )}
          {Option3Text && Option3Destination && (
            <Option
              text={Option3Text}
              destination={Option3Destination}
              image={Option3Image}
              key={Option3Text + location.key}
            />
          )}
          {Option4Text && Option4Destination && (
            <Option
              text={Option4Text}
              destination={Option4Destination}
              image={Option4Image}
              key={Option4Text + location.key}
            />
          )}

          {DownloadFile && (
            <Option
              text={DownloadText}
              image={DownloadImg}
              key={DownloadText + location.key}
              fileDownload={DownloadFile}
            />
          )}

          {ShowRestart && (
            <Option
              isRestart={true}
              text={"RESTART"}
              destination={0}
              key={"restart" + location.key}
            />
          )}
        </OptionsContainer>
      </PromptContainer>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fadevideo" timeout={1000}>
          <VideoOrImageContainer>
            {Video &&
              !Video.endsWith(".jpeg") &&
              !Video.endsWith(".jpg") &&
              !Video.endsWith(".gif") &&
              !Video.endsWith(".png") && (
                <VideoPlayer
                  style={{ margin: "auto", pointerEvents: "none" }}
                  url={Video}
                  width={"100%"}
                  height="100%"
                  controls={false}
                  playing={true}
                  onLoad={(e) => {
                    setVideoHasStarted(true);
                  }}
                />
              )}
            {Video && Video.endsWith(".jpeg") && (
              <MainImage
                onLoad={(e) => {
                  setVideoHasStarted(true);
                }}
                alt={Prompt}
                src={Video}
                isLoaded={videoHasStarted}
              />
            )}
            {Video && Video.endsWith(".gif") && (
              <MainImage
                onLoad={(e) => {
                  setVideoHasStarted(true);
                }}
                alt={Prompt}
                src={Video}
                isLoaded={videoHasStarted}
              />
            )}
            {Video && Video.endsWith(".jpg") && (
              <MainImage
                onLoad={(e) => {
                  setVideoHasStarted(true);
                }}
                alt={Prompt}
                src={Video}
                isLoaded={videoHasStarted}
              />
            )}
            {Video && Video.endsWith(".png") && (
              <MainImage
                onLoad={(e) => {
                  setVideoHasStarted(true);
                }}
                alt={Prompt}
                src={Video}
                isLoaded={videoHasStarted}
              />
            )}
          </VideoOrImageContainer>
        </CSSTransition>
      </TransitionGroup>
      <VideoOrImageContainer></VideoOrImageContainer>

      {/* <VideoOrImageContainer></VideoOrImageContainer> */}
    </OuterContainer>
  );
}
