import React from "react";
import styled, { keyframes } from "styled-components";
import { device } from "./device";

const invertAnimation = keyframes`
  0% {
    filter: invert(0);
  }
  50% {
    filter: invert(1);
  }
  100% {
    filter: invert(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  height: 100%;
  width: 100%;
  max-width: 650px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; // Spreads the items evenly in the line; first item is at the start line, last item at the end line.
  align-items: center; // Vertically aligns the items in the center.
  width: 100%; // Makes sure the div takes up the full width.
  margin: auto;
  /* max-width: 100px; */
`;

const ButtonSubContainer = styled.a`
  width: 40%; // The maximum size of the image
  height: 100px;
  position: relative;
`;

const ButtonImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  object-fit: contain;
  transition: all 0.3s ease;
  margin: 1rem;
  border-radius: 20px;
  &:hover {
    background-color: transparent;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 600px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  // animation: ${invertAnimation} 2s infinite; // Adjust duration and timing as needed
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 1rem;
  width: 100%;
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  margin: 1rem;
  width: 100%;
`;

const PrintButtonImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;

  /* margin: 1rem; */
  /* border-color: white; */
`;

const PrintContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 2rem; */
  width: 75%;
`;

const PrintSubContainer = styled.a`
  /* max-width: 70%; // The maximum size of the image */
  height: 50px;
  max-width: 600px;
  width: 100%;
  position: relative;
  border: 3px solid white;
  margin: 1rem;
  border-radius: 10px;
  /* padding: 1rem; */
  background-color: black;
  transition: all 0.3s ease;

  &:hover {
    border-color: black;
    /* background-color: pink; */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }
`;

const GameButton = styled.button`
  width: 400px;
  height: 120px;
  font-size: 3rem;
  background: #f59e0b;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  transform: translateY(0);
  transition: all 0.2s ease;
  box-shadow: 0 8px 0 #5b21b6;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 0 #5b21b6;
    &::after {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(6px);
    box-shadow: 0 4px 0 #5b21b6;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    opacity: 0;
    transition: opacity 0.2s ease;
    border-radius: 12px;
    pointer-events: none;
  }

  span {
    position: relative;
    z-index: 1;
  }

  margin-bottom: 2rem;
`;

const ManualButton = styled.button`
  width: 400px;
  height: 120px;
  font-size: 3rem;
  background: #7c3aed;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  transform: translateY(0);
  transition: all 0.2s ease;
  box-shadow: 0 8px 0 #5b21b6;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 0 #5b21b6;
    &::after {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(6px);
    box-shadow: 0 4px 0 #5b21b6;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    opacity: 0;
    transition: opacity 0.2s ease;
    border-radius: 12px;
    pointer-events: none;
  }

  span {
    position: relative;
    z-index: 1;
  }

  margin-bottom: 2rem;
`;

export default function Ttrpg() {
  return (
    <Container>
      <Title className="tac">WEB WIDE WORLD VOL 4.A</Title>
      <Subtitle className="tac">ONLINE & TABLE-TOP ROLE-PLAYING GAME</Subtitle>

      <a href="https://aotl.www-jpj.com/">
        <Image className="w-auto h-auto" src="opening.jpg" alt="Link to game" />
      </a>

      <GameButton
        onClick={() => (window.location.href = "https://aotl.www-jpj.com/")}
      >
        Play Now
      </GameButton>

      <ManualButton
        onClick={() =>
          (window.location.href =
            "https://drive.google.com/file/d/144fiymMzEhK2oFiSk3t2sRJgCN7aSbHg/view?usp=sharing")
        }
      >
        Read Player Guide
      </ManualButton>

      <Subtitle className="tac">
        Artwork and Story:{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          John Powell-Jones
        </span>
      </Subtitle>

      <Subtitle className="tac">
        Game Design & Development:{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          John Howes
        </span>
      </Subtitle>

      <Subtitle className="tac">
        OST:{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Aliyah Hussain
        </span>
      </Subtitle>

      <iframe
        style={{ border: "0", width: "100%", height: "42px", margin: "1rem" }}
        src="https://bandcamp.com/EmbeddedPlayer/album=4279582482/size=small/bgcol=181a1b/linkcol=056cc4/transparent=true/"
        seamless
        title="ost"
      >
        <a href="https://webwideworld1.bandcamp.com/album/assault-on-the-leviathan-ost">
          Assault On The Leviathan OST by Aliyah Hussain
        </a>
      </iframe>

      <a href="/www">
        <Image
          className="w-auto h-auto"
          src="WebWideWorld.gif"
          alt="Link to game"
        />
      </a>

      <ButtonSubContainer className="tac" href="/privacy">
        Privacy
      </ButtonSubContainer>
    </Container>
  );
}
