import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OptionContainer = styled.div`
  background-color: ${(props) => (props.isRestart ? "red" : "hotpink")};
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  width: 80%;
  border: ${(props) =>
    props.isRestart ? "1px solid red" : "1px solid hotpink"};
  &:hover {
    transform: translate(0px, -3px);
  }

  transition: opacity 2000ms ease, transform 60ms ease;
`;

const StyledLink = styled(Link)`
  opacity: ${(props) => (props.buttonsactive ? 1 : 0)};
  pointer-events: ${(props) => (props.buttonsactive ? "default" : "none")};
  cursor: ${(props) => (props.buttonsactive ? "pointer" : "none")};
  transition: opacity 2000ms ease, transform 60ms ease;
`;

export default function Option({
  text,
  destination,
  image,
  isRestart,
  audio,
  fileDownload,
}) {
  const [buttonsActive, setButtonsActive] = useState(false);

  useEffect(() => {
    setButtonsActive(false);
    setTimeout(() => {
      setButtonsActive(true);
    }, 3000);
  }, []);
  return (
    <StyledLink
      to={!fileDownload && "/" + destination}
      buttonsactive={buttonsActive ? 1 : undefined}
    >
      <OptionContainer isRestart={isRestart}>
        {image && (
          <img
            style={{ width: "100px" }}
            src={image}
            alt={text}
            onClick={() => {
              if (fileDownload) {
                window.open(
                  fileDownload,
                  "Popup",
                  "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
                );
              }
            }}
          />
        )}
        <h2>{text}</h2>
      </OptionContainer>
    </StyledLink>
  );
}
