import React from "react";
import styled, { keyframes } from "styled-components";
import { device } from "./device";

const invertAnimation = keyframes`
  0% {
    filter: invert(0);
  }
  50% {
    filter: invert(1);
  }
  100% {
    filter: invert(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  height: 100%;
  width: 100%;
  max-width: 650px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around; // Spreads the items evenly in the line; first item is at the start line, last item at the end line.
  align-items: center; // Vertically aligns the items in the center.
  width: 100%; // Makes sure the div takes up the full width.
  margin: 1rem;
  /* max-width: 100px; */
`;

const ButtonSubContainer = styled.a`
  width: 40%; // The maximum size of the image
  height: 100px;
  position: relative;
`;

const ButtonImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  object-fit: contain;
  transition: all 0.3s ease;
  margin: 1rem;
  border-radius: 20px;
  &:hover {
    background-color: transparent;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 400px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  animation: ${invertAnimation} 2s infinite; // Adjust duration and timing as needed
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 1rem;
  width: 100%;
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  margin: 1rem;
  width: 100%;
`;

const PrintButtonImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;

  /* margin: 1rem; */
  /* border-color: white; */
`;

const PrintContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 2rem; */
  width: 75%;
`;

const PrintSubContainer = styled.a`
  /* max-width: 70%; // The maximum size of the image */
  height: 50px;
  max-width: 600px;
  width: 100%;
  position: relative;
  border: 3px solid white;
  margin: 1rem;
  border-radius: 10px;
  /* padding: 1rem; */
  background-color: black;
  transition: all 0.3s ease;

  &:hover {
    border-color: black;
    /* background-color: pink; */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }
`;

export default function Home() {
  return (
    <Container>
      <Title className="tac">WEB WIDE WORLD</Title>
      <Subtitle className="tac">
        Join Atamur as they navigate through the mysterious world of Durt,
        trying to learn who they are and how they got here.
      </Subtitle>

      <Image className="tac" src="home.png" alt="Your Alt Text" />

      <ButtonContainer>
        <ButtonSubContainer
          href="https://john-powell-jones.itch.io/web-wide-world"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonImage alt="Available On Itch" src="badge.svg" />
        </ButtonSubContainer>
        {/* <ButtonSubContainer
          href="app-store-url"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonImage alt="Available On The App Store" src="appstore.svg" />
        </ButtonSubContainer> */}
      </ButtonContainer>

      <Subtitle className="tac">
        Who is the sentient suit of armour that led you to the place you've
        awoken{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          and why are there so many maggots!?!?!
        </span>
      </Subtitle>

      <Subtitle className="tac">
        Artwork and Story:{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          John Powell-Jones
        </span>
      </Subtitle>

      <Subtitle className="tac">
        Technical Development:{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          John Howes
        </span>
      </Subtitle>

      <Subtitle className="tac">
        Sound Design:{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Aliyah Hussain
        </span>
      </Subtitle>

      <Subtitle className="tac">
        Poetry and Prose:{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Lauren De Sa Naylor
        </span>
      </Subtitle>

      <PrintContainer>
        <PrintSubContainer
          href="https://johnpowelljones.bigcartel.com/product/web-wide-world-vol-1-reprint"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PrintButtonImage
            alt="Web Wide World Print Edition Volume 1"
            src="vol1.png"
          />
        </PrintSubContainer>
        <PrintSubContainer
          href="https://johnpowelljones.bigcartel.com/product/web-wide-world-volume-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PrintButtonImage
            alt="Web Wide World Print Edition Volume 2"
            src="vol2.png"
          />
        </PrintSubContainer>
        <PrintSubContainer
          href="https://johnpowelljones.bigcartel.com/product/web-wide-world-volume-3"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PrintButtonImage
            alt="Web Wide World Print Edition Volume 3"
            src="vol3.png"
          />
        </PrintSubContainer>
        <PrintSubContainer
          href="https://johnpowell-jones.bandcamp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PrintButtonImage alt="Soundtrack" src="soundtrack.png" />
        </PrintSubContainer>
      </PrintContainer>
      <ButtonSubContainer className="tac" href="/privacy">
        Privacy
      </ButtonSubContainer>
    </Container>
  );
}
