import React, { useState, useEffect } from "react";
import styled from "styled-components";

const PromptSpan = styled.span`
  position: relative;
  animation: move-text-color 0.75s forwards;
  bottom: -1em;
  opacity: 0;
  @keyframes move-text-color {
    0% {
      bottom: -0.2em;
      opacity: 1;
      color: #3e5c76;
    }

    50% {
      bottom: 0.2em;
      color: #ec058e;
    }

    100% {
      bottom: 0;
      opacity: 1;
      color: #cb3126;
    }
  }
`;

export default function SplitText({ prompt, animationLength }) {
  const [currentPrompt, setCurrentPrompt] = useState("");
  useEffect(() => {
    if (prompt !== currentPrompt) {
      setCurrentPrompt("");
      setTimeout(() => {
        setCurrentPrompt(prompt);
      }, 300);
    }
  }, [prompt, currentPrompt]);

  return (
    <span style={{ margin: "1rem", height: "100%" }}>
      {currentPrompt.split("").map(function (char, index) {
        let style = { animationDelay: 0.5 + index / 30 + "s" };
        return (
          <PromptSpan aria-hidden="true" key={index} style={style}>
            {char}
          </PromptSpan>
        );
      })}
    </span>
  );
}
