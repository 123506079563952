import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OuterContainer = styled.div`
  grid-area: 1/1/4/4;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: black;
`;

const PromptContainer = styled.div`
  background-color: hotpink;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  max-width: 90vw;
  margin: auto;
`;

const MainImage = styled.img`
  /* width: 100%; */
  height: 100%;
  max-width: 90vw;
  object-fit: contain;
  margin: auto;
`;

export default function MainMenu({ setIsPlaying }) {
  return (
    <OuterContainer
      onClick={() => {
        setIsPlaying(true);
      }}
    >
      <Link to="/0">
        <div
          style={{
            // width: "100%",
            display: "flex",
            margin: "1rem",
            // padding: "1rem",
          }}
        >
          <MainImage
            alt="Web Wide World Splash Screen"
            src="WebWideWorld.gif"
          />
        </div>
      </Link>

      <Link to="/0">
        <PromptContainer>
          <h3>CLICK TO BEGIN</h3>
        </PromptContainer>
      </Link>
    </OuterContainer>
  );
}
